import { useMemo } from 'react';

export default (t) => useMemo(() => ({
  'ui.disableLoginLanguage': {
    name: t('attributeUiDisableLoginLanguage'),
    type: 'boolean',
  },
  /* NEW ATTRIBUTES  */
  'dailyReport.computeTime': {
    name: t('attributeDailyReportComputeTime'),
    type: 'time',
  },
  'dailyReport.sendTime': {
    name: t('attributeDailyReportSendTime'),
    type: 'time',
  },
  /* END NEW ATTRIBUTES */
}), [t]);
