import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Stack,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import EditItemView from '../settings/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import ReportsMenu from './components/ReportsMenu';
import { useEffectAsync } from '../reactHelper';
import { formatDistanceNumeric, formatDistanceUnit, formatMsecToHours } from '../common/util/formatter';
import { useAttributePreference } from '../common/util/preferences';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const MaintenanceRecordPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { deviceId, id } = useParams();
  const devices = useSelector((state) => state.devices.items);
  const device = devices[deviceId || id] ? devices[deviceId || id] : {};
  const base = id ? null : { deviceId, maintenanceId: id, attributes: {}, cost: 0 };
  const [item, setItem] = useState(base);
  const [position, setPosition] = useState({});
  const [computedMaintenances, setComputedMaintenances] = useState([]);
  const [maintenanceRecords, setMaintenanceRecords] = useState([]);

  const distanceUnit = useAttributePreference('distanceUnit');

  const validate = () => item && item.deviceId && item.maintenanceId && item.date && item.lecture && item.teoricLecture && !(item.cost)?.isNaN;

  const cascadeActivities = (maintenanceId) => {
    const maintenance = computedMaintenances.find((categoryMaintenance) => categoryMaintenance.id === maintenanceId);
    let activities = maintenance.details?.map((activity) => ({ maintenance: maintenance.name, name: activity, checked: false }));
    if (maintenance.maintenanceId) {
      activities = activities.concat(cascadeActivities(maintenance.maintenanceId));
    }
    return activities;
  };

  useEffectAsync(async () => {
    if (device.id) {
      const response = await fetch(`/api/express/maintenances/devices/${device.id}`);
      const queryParams = new URLSearchParams({ id: device.positionId });
      const posisitonResponse = await fetch(`/api/positions?${queryParams.toString()}`);
      if (response.ok && posisitonResponse.ok) {
        const items = await response.json();
        const positions = await posisitonResponse.json();
        setPosition(positions[0]);
        setMaintenanceRecords(items.maintenanceRecords);
        setComputedMaintenances(items.categoryMaintenances);
      } else {
        throw Error(await response.text());
      }
    }
  }, [device]);

  const handleChangeMaintenance = (e) => {
    const maintenanceId = e.target.value;
    if (maintenanceId) {
      const activities = cascadeActivities(maintenanceId).reverse();
      let lecture = 0;
      let teoricLecture = 0;
      const maintenance = computedMaintenances.find((categoryMaintenance) => categoryMaintenance.id === maintenanceId);
      const lastMaintenanceOfType = maintenanceRecords.find((record) => record.maintenanceId === maintenanceId);
      let remainder = 1;
      switch (maintenance.type) {
        case 'hours':
          lecture = formatMsecToHours(position.attributes?.hours).toFixed(0);
          remainder = Math.floor(lecture / maintenance.interval);
          remainder = remainder > 0 ? remainder : 1;
          console.log(lecture, maintenance.interval, remainder);
          if (lastMaintenanceOfType) {
            teoricLecture = formatMsecToHours(lastMaintenanceOfType.lecture) + maintenance.interval;
          } else {
            teoricLecture = maintenance.interval * remainder;
          }
          break;
        case 'distance':
          lecture = formatDistanceNumeric(position.attributes?.totalDistance, distanceUnit);
          remainder = Math.floor(lecture / maintenance.interval);
          remainder = remainder > 0 ? remainder : 1;
          if (lastMaintenanceOfType) {
            teoricLecture = lastMaintenanceOfType.lecture + (maintenance.interval * remainder);
          } else {
            teoricLecture = maintenance.interval * remainder;
          }
          break;
        default:
          break;
      }
      setItem({ ...item, maintenanceId, attributes: { ...item.attributes, activities }, lecture, teoricLecture });
    } else {
      setItem({ ...item, maintenanceId, attributes: { ...item.attributes, activities: [] } });
    }
  };

  const formatUnits = (value) => {
    switch (value) {
      case 'hours':
        return t('sharedHourAbbreviation');
      case 'distance':
        return formatDistanceUnit(distanceUnit, t);
      default:
        return '';
    }
  };

  return (
    <EditItemView
      endpoint="express/maintenances/records"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<ReportsMenu />}
      breadcrumbs={['settingsTitle', 'sharedMaintenance']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                label={t('sharedDevice')}
                value={device.name || ''}
                disabled
              />
              <FormControl>
                <InputLabel>{t('sharedMaintenance')}</InputLabel>
                <Select
                  label={t('sharedMaintenance')}
                  value={item.maintenanceId || ''}
                  onChange={handleChangeMaintenance}
                  disabled={Boolean(!computedMaintenances.length || item?.id)}
                  error={!item.maintenanceId}
                >
                  {
                    item.computedMaintenance ?
                      <MenuItem key={item.computedMaintenance.id} value={item.computedMaintenance.id}>{item.computedMaintenance.name}</MenuItem>
                      :
                      computedMaintenances.map((maintenance) => (
                        <MenuItem key={maintenance.id} value={maintenance.id}>{maintenance.name}</MenuItem>
                      ))
                  }
                </Select>
              </FormControl>
              <TextField
                label={t('sharedDate')}
                type="date"
                value={item.date ? moment(item.date).locale('en').format(moment.HTML5_FMT.DATE) : ''}
                onChange={(e) => setItem({ ...item, date: moment(e.target.value, moment.HTML5_FMT.DATE).locale('en').format() })}
                InputLabelProps={{ shrink: true }}
                disabled={!item.maintenanceId || item.id}
              />
              <TextField
                label={t('sharedLecture')}
                type="number"
                value={item.lecture || ''}
                onChange={(e) => setItem({ ...item, lecture: e.target.value })}
                InputProps={
                  {
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment sx={{ ml: 2 }} position="end">
                        {formatUnits(computedMaintenances.find((maintenance) => maintenance.id === item.maintenanceId)?.type)}
                      </InputAdornment>
                    ),
                  }
                }
                disabled={!item.maintenanceId || item.id}
              />
              <TextField
                label={t('sharedTeoricLecture')}
                type="number"
                value={item.teoricLecture || ''}
                onChange={(e) => setItem({ ...item, teoricLecture: e.target.value })}
                InputProps={
                  {
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment sx={{ ml: 2 }} position="end">
                        {formatUnits(computedMaintenances.find((maintenance) => maintenance.id === item.maintenanceId)?.type)}
                      </InputAdornment>
                    ),
                  }
                }
                disabled
              />
              <NumericFormat
                label={t('sharedCost')}
                value={item.cost || 0}
                onChange={(e) => setItem({ ...item, cost: e.target.value.replaceAll('.', '') })}
                customInput={TextField}
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                allowLeadingZeros={false}
                allowedDecimalSeparators={['.', ',']}
                InputProps={{ startAdornment: '$' }}
                variant="outlined"
                valueIsNumericString
                disabled={!item.maintenanceId}
                autoComplete="off"
              />
              <TextField
                label={t('maintenanceInvoiceLink')}
                type="search"
                value={item.attributes.invoice || ''}
                onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, invoice: e.target.value } })}
                disabled={!item.maintenanceId}
              />

              <div>
                <Accordion disabled={!item.attributes?.activities}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Stack direction="row" spacing={0} alignItems="center">
                      <Checkbox
                        checked={item.attributes?.activities?.every((activity) => activity.checked)}
                        indeterminate={item.attributes?.activities?.some((activity) => activity.checked) && item.attributes?.activities?.some((activity) => !activity.checked)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          const activities = item.attributes.activities.map((activity) => ({ ...activity, checked: e.target.checked }));
                          setItem({ ...item, attributes: { ...item.attributes, activities } });
                        }}
                      />
                      <Typography variant="subtitle1">
                        {t('maintenanceActivities')}
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    {item.attributes?.activities?.map((activity, idx) => (
                      <FormControlLabel
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${idx}${activity.name}`}
                        name="activities"
                        control={(
                          <Checkbox
                            checked={activity.checked}
                            onChange={(e) => {
                              const activities = [...item.attributes.activities];
                              activities[idx].checked = e.target.checked;
                              setItem({ ...item, attributes: { ...item.attributes, activities } });
                            }}
                          />
                        )}
                        label={`${activity.name} (${activity.maintenance})`}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              </div>
              {item.maintenanceId && (
                <TextField
                  multiline
                  rows={4}
                  label={t('maintenanceObservations')}
                />
              )}
            </AccordionDetails>
          </Accordion>
          {/* <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{}}
          /> */}
        </>
      )}
    </EditItemView>
  );
};

export default MaintenanceRecordPage;
